import axios from "axios";

const baseUrl = "https://est.efleet-aggregator-platform.com/";

export function config() {
	axios.defaults.baseURL = baseUrl;
	axios.defaults.responseType = "json";
	axios.defaults.headers.common["Content-Type"] = "application/json";
	axios.defaults.headers.common.post = {};
	axios.defaults.headers.common.put = {};
	axios.defaults.xsrfCookieName = "_CSRF";
	axios.defaults.xsrfHeaderName = "CSRF";
}

config();
