import React, { Component } from "react";
import app from "./services/app";
import MapGoogle from "./MapGoogle";

class App extends Component {
	constructor(props) {
		super(props);

		this.state = { data: [], currentSite: {}, zoom: 5, isMap: true };
	}

	componentDidMount() {
		this.loadData();
	}

	loadData = async () => {
		let result = await app.getListSites();
		this.setState({ data: result });
		// this.setState({ currentSite: this.state.data[0] });
	};

	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve);
		});
	}

	onPickSite = (site) => {
		this.setState({ currentSite: site });
	};

	render() {
		let dataTable = this.state.data.map((site, index) => (
			<div
				onClick={() => this.onPickSite(site)}
				key={site.site_id}
				className={
					"row " +
					(site == this.state.currentSite ? "row-active" : "")
				}>
				<div className="col col1">{site.site_id}</div>
				<div className="col col6">{site.site_name}</div>
				<div className="col col3">
					{site.site_alarm == 0 ? (
						<img src={require("./assets/icon_no_alert.png")} />
					) : (
						<img src={require("./assets/icon_alert.png")} />
					)}
				</div>
			</div>
		));

		return (
			<div id="main">
				<div id="nav">
					<a href="#">
						<img
							src={require("./assets/icons_SVG/icon_location_msd.png")}
						/>
					</a>
					<a href="#">
						<img
							src={require("./assets/icons_SVG/icon_people_group.png")}
						/>
					</a>
					<a href="#">
						<img
							src={require("./assets/icons_SVG/icon_profile.png")}
						/>
					</a>
				</div>
				<div id="site-title">Efleet Aggregator Platform</div>
				<div id="left-panel">
					<div id="top-left">
						<p className="big-text">{this.state.data?.length}</p>
						<div className="blue-text">TOTAL OF SITES</div>
					</div>
					<div id="middle-left">
						<div id="table">
							<div className="header">
								<div className="col col1">No</div>
								<div className="col col6">Site Name</div>
								<div className="col col3">Alarm</div>
							</div>
							<div className="separator"></div>
							<div className="table-content">{dataTable}</div>
							<div id="add-new-site">
								<img
									src={require("./assets/icons_SVG/add_new_site.png")}
								/>
							</div>
						</div>
					</div>
					<div id="bottom-left">
						{this.state.currentSite.site_id && (
							<div>
								<p className="site-title">
									{this.state.currentSite.site_name}
								</p>
								<p className="site-title">
									{"(" +
										this.state.currentSite.site_reference +
										")"}
								</p>
								<p className="blue-title">Location Detail:</p>
								<span
									style={{ fontWeight: "normal" }}
									className="site-title">
									{this.state.currentSite.site_address}
								</span>
								<div
									style={{
										textAlign: "center",
										marginRight: 15.8,
										marginTop: 10,
									}}>
									<a id="go-to-site" href="#">
										<img
											src={require("./assets/icons_SVG/button_go_to_site.png")}
										/>
									</a>
								</div>
							</div>
						)}
					</div>
				</div>
				<div id="right-panel">
					{this.state.data.length > 0 && (
						<div id="pana-map">
							<MapGoogle
								zoom={this.state.zoom}
								locations={this.state.data}
								onPickSite={this.onPickSite}
								currentSite={this.state.currentSite}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default App;
