import React, { Component } from "react";
import {
	GoogleMap,
	Marker,
	InfoWindow,
	LoadScript,
} from "@react-google-maps/api";
import mapStyle from "./map.json";

const containerStyle = {
	width: "100%",
	height: "100%",
};

const center = {
	lat: 38.3792328,
	lng: -96.3346466,
};

class GoogleMapComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showInfoWindow: false,
			currentMarker: {},
			isRender: true,
		};
	}

	_onPickSite = (site) => {
		this.props.onPickSite(site);
	};
	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve);
		});
	}

	reset = () => {};

	getMarker = (site) => {
		let currentSite = this.props.currentSite;
		let imgUrl = require("./assets/icons_SVG/icon_map_location_green.png");

		if (site == currentSite) {
			if (site.site_alarm == 0) {
				imgUrl = require("./assets/icons_SVG/icon_map_location_green_selected.png");
			} else {
				imgUrl = require("./assets/icons_SVG/icon_map_location_red_selected.png");
			}
		} else {
			if (site.site_alarm == 0) {
				imgUrl = require("./assets/icons_SVG/icon_map_location_green.png");
			} else {
				imgUrl = require("./assets/icons_SVG/icon_map_location_red.png");
			}
		}

		return imgUrl;
	};

	render() {
		let markers = this.props.locations.map((ele) => (
			<Marker
				key={ele.site_id}
				onClick={() => {
					this._onPickSite(ele);
				}}
				onMouseOver={() => {
					this.setState({
						currentMarker: {
							position: {
								lat: parseFloat(ele.site_lat),
								lng: parseFloat(ele.site_lng),
							},
							title: ele.site_reference,
							name: ele.site_name,
						},
					});
					this.setState({ showInfoWindow: true });
				}}
				onMouseOut={() => {
					this.setState({ showInfoWindow: false });
				}}
				icon={this.getMarker(ele)}
				position={{
					lat: parseFloat(ele.site_lat),
					lng: parseFloat(ele.site_lng),
				}}
			/>
		));

		return (
			<LoadScript googleMapsApiKey="AIzaSyB0TaxTbC4plpdP3nqF6lPMCPjx0U7FuIo">
				<div
					onClick={async () => {
						await this.setStateAsync({ isRender: false });
						this.setState({ isRender: true });
					}}
					id="reset-zoom"></div>
				{this.state.isRender && (
					<GoogleMap
						mapContainerStyle={containerStyle}
						gestureHandling={"greedy"}
						options={{
							styles: mapStyle,
							gestureHandling: "greedy",
							disableDefaultUI: true, // a way to quickly hide all controls
							mapTypeControl: false,
							scaleControl: true,
							zoomControl: true,
							keyboardShortcuts: false,
							minZoom: 4,
						}}
						center={center}
						zoom={this.props.zoom}>
						{markers}
						{this.state.showInfoWindow && (
							<InfoWindow
								position={this.state.currentMarker.position}>
								<div className="info-window">
									<p>{this.state.currentMarker?.name}</p>
									<p>
										{"(" +
											this.state.currentMarker?.title +
											")"}
									</p>
								</div>
							</InfoWindow>
						)}
					</GoogleMap>
				)}
			</LoadScript>
		);
	}
}

export default GoogleMapComponent;
