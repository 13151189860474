import config from "./config";
import axios from "axios";

async function getListSites(body) {
	// let response = await axios.get(
	// 	"/api/getSiteSummary?X-token=92513e5d-fdf0-4ba2-9908-caa964a943e4-ba5e1563"
	// );
	// return response;

	const response = [
		{
			site_id: 1,
			site_reference: "PGE-001",
			site_name: "Daimler Innovation Site 1",
			site_alarm: 0,
			site_address: "4717 North Lagoon Avenue, Portland, OR, 97217",
			site_lat: "45.55727548",
			site_lng: "-122.70309460",
		},
		{
			site_id: 2,
			site_reference: "PGE-002",
			site_name: "Daimler Innovation Site 2",
			site_alarm: 0,
			site_address: "787 Alameda Street, Los Angeles, CA, 90021",
			site_lat: "34.03338752",
			site_lng: "-118.24071110",
		},
		{
			site_id: 3,
			site_reference: "PGE-003",
			site_name: "Daimler Innovation Site 3",
			site_alarm: 0,
			site_address: "4166 Royal Palm Avenue, Miami Beach, FL, 33140",
			site_lat: "25.81454811",
			site_lng: "-80.12983235",
		},
		{
			site_id: 4,
			site_reference: "PGE-004",
			site_name: "Daimler Innovation Site 4",
			site_alarm: 0,
			site_address: "4400 Telephone Road, Houston, TX, 77087",
			site_lat: "29.69916643",
			site_lng: "-95.30295718",
		},
		{
			site_id: 5,
			site_reference: "PGE-005",
			site_name: "Daimler Innovation Site 5",
			site_alarm: 1,
			site_address: "1400 Quitman Street, Denver, CO, 80204",
			site_lat: "39.73868178",
			site_lng: "-105.04024200",
		},
		{
			site_id: 6,
			site_reference: "ABC-001",
			site_name: "Electric Island 1",
			site_alarm: 0,
			site_address:
				"50 Massachusetts Avenue Northeast, Washington, DC, 20002",
			site_lat: "38.89794681",
			site_lng: "-77.00508223",
		},
		{
			site_id: 7,
			site_reference: "ABC-002",
			site_name: "Electric Island 2",
			site_alarm: 0,
			site_address: "750 North 50th Street, Seattle, WA, 98103",
			site_lat: "47.66581611",
			site_lng: "-122.35048990",
		},
		{
			site_id: 8,
			site_reference: "ABC-003",
			site_name: "Electric Island 3",
			site_alarm: 1,
			site_address: "314 Murfreesboro Pike, Nashville, TN 37210",
			site_lat: "36.14657364",
			site_lng: "-86.75467619",
		},
		{
			site_id: 9,
			site_reference: "ABC-004",
			site_name: "Electric Island 4",
			site_alarm: 0,
			site_address: "911 Casino Center Drive, Hammond, IN, 46320",
			site_lat: "41.69715736",
			site_lng: "-87.51199663",
		},
		{
			site_id: 10,
			site_reference: "ABC-005",
			site_name: "Electric Island 5",
			site_alarm: 0,
			site_address: "14925 Cordes Lakes Road, Mayer, AZ, 86333",
			site_lat: "34.32737914",
			site_lng: "-112.11817360",
		},
		{
			site_id: 11,
			site_reference: "USA-001",
			site_name: "Charging Avenue 1",
			site_alarm: 0,
			site_address: "5501 Anthony Wayne Drive, Detroit, MI 48202",
			site_lat: "42.35714944",
			site_lng: "-83.07307873",
		},
		{
			site_id: 12,
			site_reference: "USA-002",
			site_name: "Charging Avenue 2",
			site_alarm: 0,
			site_address: "10 Central Road, New York, NY, 10035",
			site_lat: "40.79447226",
			site_lng: "-73.92113443",
		},
		{
			site_id: 13,
			site_reference: "USA-003",
			site_name: "Charging Avenue 3",
			site_alarm: 0,
			site_address: "100 Cambridgeside Place, Cambridge, MA, 02141",
			site_lat: "42.36668363",
			site_lng: "-71.07719347",
		},
		{
			site_id: 14,
			site_reference: "USA-004",
			site_name: "Charging Avenue 4",
			site_alarm: 0,
			site_address: "800 Mount Rushmore Road, Custer, SD, 57730",
			site_lat: "43.76728971",
			site_lng: "-103.59494060",
		},
		{
			site_id: 15,
			site_reference: "USA-005",
			site_name: "Charging Avenue 5",
			site_alarm: 0,
			site_address: "4155 South Grand Canyon Drive, Las Vegas, NV, 89147",
			site_lat: "36.11400533",
			site_lng: "-115.31229270",
		},
	];

	return response;
}

export default {
	getListSites: getListSites,
};
